<template>
  <div class="container">
    <div class="row">
      <div class="col-8 mx-auto">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col text-center">
                <h4 class="alert alert-danger py-4 mb-3"><i class="far fa-user-clock fa-3x"></i><br /><br />Your subscription has expired.</h4>
                <h5 class="mb-3">Please update your billing information to continue using Swandoola.</h5>
                <p class="mb-3">Your data is safe in the meantime and once resolved you can continue right from where you left off.</p>
                <button class="btn btn-primary btn-lg" @click="goTo('/billing/history')"><i class="fa text-white fa-file-invoice me-2"></i>Go to Billing</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ["user"],
  components: {
  },
  methods: {
    goTo(url) {
      this.$router.push(url);
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    }
  },
};
</script>

<style>
</style>
